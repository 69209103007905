"use client";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";
import { CiSearch } from "react-icons/ci";
import ContactButton from "@/components/ContactButton";

const navigation = [
  // { name: "Home", href: "/" },
  {
    href: "/about",
    name: "About",
    /* submenu: [{ href: "/reviews", name: "Reviews" }], */
  },
  { name: "Menu", href: "/menu/" },
  { name: "Wedding", href: "/services/weddings" },
  { name: "Event Management", href: "/services/event-management" },
  { name: "Private Dining", href: "/services/private-dining" },
  { name: "Events", href: "/services/events" },
  // {
  //   href: "/services",
  //   name: "Services",
  //   submenu: [
  //     { href: "/weddings", name: "WEDDINGS" },
  //     { href: "/bar-bat-mitzvahs", name: "BAR/BAT MITZVAHS" },
  //     { href: "/event-management", name: "EVENT MANAGEMENT" },
  //     { href: "/overseas-weddings", name: "OVERSEAS WEDDINGS" },
  //   ],
  // },
  { name: "Gallery", href: "/gallery/" },
  // { name: "Blog", href: "/blog/" },
  // { name: "Contact", href: "/contact/" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20 font-sans">
      <Disclosure as="nav" className="bg-white">
        {({ open, close }) => (
          <>
            <div className="px-2 mx-auto ">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-center sm:justify-start">
                  <div className="flex-shrink-0 mx-5 lg:mx-0 pl-2 lg:pl-0">
                    <Link href="/">
                      <div className="font-calligraphy text-4xl lg:text-[2.750rem] text-pink-400">
                        Garden Suburb Kitchen
                      </div>
                      {/* <Image
                        src="/images/Garden-Suburb-Kitchen-logo-2.png"
                        alt="logo"
                        width={320}
                        height={45}
                        className=" w-60 h-full lg:w-auto lg:h-full"
                      /> */}
                    </Link>
                  </div>
                  <div className="hidden sm:block ml-6 lg:ml-0 flex-1 self-center">
                    <div className="flex space-x-6 justify-center">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-gray-500 text-base px-3 py-2  rounded-md font-medium flex items-center uppercase hover:text-pink-500"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10  bg-white  shadow-lg border-t py-5 mt-7 w-60">
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-6 py-4 text-sm tracking-widest text-black font-medium uppercase hover:bg-gray-100 hover:text-pink-500"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-gray-500 px-0 py-2 tracking-widest rounded-md text-base font-medium hover:text-pink-500 uppercase"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                  {/* <CiSearch className="w-6 h-6 hidden lg:block" /> */}
                  <div className=" hidden lg:block">
                    <ContactButton />
                  </div>
                  {/* <Link
                    href="/contact"
                    className="bg-colorGray-200 hover:bg-colorGray-400 text-colorGray-800 hover:text-white uppercase hidden lg:block text-base duration-300 ease-in-out ml-4 px-8 py-2 font-semibold"
                  >
                    CONTACT US
                  </Link> */}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
                <div className=" block lg:hidden">
                  <ContactButton />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
