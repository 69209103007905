"use client";
import Header from "@/components/Header";
import { useEffect, useState } from "react";

const StickyHeader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = () => {
    if (typeof window !== "undefined") {
      // Check the scroll position
      const currentScrollY = window.scrollY;

      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header
      } else {
        setIsVisible(true); // Show header
      }

      setLastScrollY(currentScrollY); // Update the last scroll position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed1 top-01 w-full bg-white shadow-md transition-transform duration-300 ease-in-out z-[999] ${
        !isVisible && "-translate-y-full"
      }`}
    >
      {/* <section className=" gradient-ltr text-base py-1 font-light">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-3 items-center justify-end font-medium text-white divide-x divide-white">
            <div className="md:col-span-3 lg:col-span-4 text-right pr-2">Email: rajiv@ergosmind.com</div>
            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <div className="flex items-center justify-end space-x-2">
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#" ><FaFacebook size={20} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2" href="#"><FaTwitter size={20} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#"><FaInstagram size={20} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2" href="#"><FaLinkedin size={20} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2" href="#"><FaYoutube size={20} /></a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Header />
    </div>
  );
};

export default StickyHeader;
