"use client";
import { useState } from "react";

const GetInTouchForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle form open/close state
  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative ">
      {/* Get in Touch Button */}
      <button
        onClick={toggleForm}
        className="fixed  right-0 top-1/2 transform -translate-y-1/2 bg-white text-black w-12 h-48  shadow-lg flex flex-col justify-center items-center "
      >
        <span className="transform w-48 rotate-90 font-bold text-black tracking-wider">
          GET IN TOUCH
        </span>
        <span className="absolute left-[-18px] py-[4px] px-3 bg-green-600 text-white rounded-full">
          {isOpen ? ">" : "<"}
        </span>
      </button>

      {/* Popup Form */}
      <div
        className={`fixed top-28 right-0 h-[570px] w-[400px] bg-gray-50 shadow-lg z-50 transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Close Button */}
        <button
          onClick={toggleForm}
          className="absolute left-3 text-black text-4xl"
        >
          &times;
        </button>

        <div className="p-8 text-center justify-center items-center">
          <h2 className="text-lg font-extrabold mb-0 ">
            GET IN TOUCH WITH YOUR CATERING NEEDS
          </h2>
          <p className="text-sm mb-8 text-gray-700">
            Call us on <span className="text-green-600">0000 000 0000</span>
          </p>
          <form>
            <div className="mb-4">
              <input
                type="text"
                className="w-full border-b pb-2 bg-gray-50 text-sm"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="w-full border-b pb-2 bg-gray-50 text-sm"
                placeholder="Enter your company name"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                className="w-full border-b pb-2 bg-gray-50 text-sm"
                placeholder="Enter your email"
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <input
                  type="tel"
                  className="w-full border-b pb-2 bg-gray-50 text-sm"
                  placeholder="Enter your phone"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="w-full border-b pb-2 bg-gray-50 text-sm"
                  placeholder="Enter your postcode"
                />
              </div>
            </div>
            <div className="mb-4">
              <textarea
                className="w-full border-b pb-2 bg-gray-50 text-sm"
                placeholder="Enter your enquiry"
                rows="3"
              ></textarea>
            </div>

            <div className="mb-4">
              <input type="checkbox" id="captcha" />
              <label htmlFor="captcha" className="ml-2 text-sm">
                I'm not a robot
              </label>
            </div>
            <button
              type="submit"
              className=" bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchForm;
